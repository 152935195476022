import axios from 'axios'

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || ''
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
let config = {
  baseURL: process.env.VUE_APP_API_URL || ''
  // timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
}

const _http = axios.create(config)

//  request interceptors 
_http.interceptors.request.use(
  config => {
    // Do something before request is sent
    return config
  },
  error => {
    // Do something with request error
    return Promise.reject(error)
  }
)

// response interceptor
_http.interceptors.response.use(
  res => {
    
    return res.data
  },
  error => {
    // if not token return login
    console.log('err：', error)
    console.dir(error)
    
    if (error) {
    //   Vue.prototype.$message.error('网络请求失败')
      /*
      let msg = error.response.data.message
      if (msg) {
        // show error tips
        // ...

        // error status
        if (error.response.status === 401) {
          router.push('/login')
        }
      }
      */
    }
    return Promise.reject(error)
  }
)

// Plugin.install = function(Vue) {
//   Vue.axios = _http
//   window.axios = _http
//   Object.defineProperties(Vue.prototype, {
//     $axios: {
//       get() {
//         return _http
//       }
//     }
//   })
// }

// Vue.use(Plugin)

export default _http;
