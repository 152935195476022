<template>
  <div id="app">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <i class="el-icon-truck"></i>
        <span> Vehicle History Report</span>
      </div>
      <div v-for="(item, index) in vehicleDetails" :key="index">
        {{index}}:  {{item}}
      </div>
    </el-card>
    <el-divider></el-divider>
    <el-table class="non-header" :data="titleTableData" style="width: 100%">
      <el-table-column prop="titleDesc"> </el-table-column>
    </el-table>
    <el-divider></el-divider>
    <el-table :data="sumOwnModuleData" stripe border style="width: 100%">
      <el-table-column prop="history" label="Ownership History">
      </el-table-column>
      <el-table-column :prop="'owner' + (index + 1)" :label="item" v-for="(item, index) in sumOwnModuleHeader" :key="index"> </el-table-column>
      <!-- <el-table-column prop="owner2" label="Owner 2"> </el-table-column>
      <el-table-column prop="owner3" label="Owner 3"> </el-table-column> -->
    </el-table>
    <el-divider></el-divider>
    <div v-show="rows1.length != 0">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <i class="el-icon-user"></i>
        <span> {{owners1_desc.owner_purchased}}</span>
      </div>
      <div>{{owners1_desc.owner_type}}</div>
      <div>{{owners1_desc.drven_year}}</div>
    </el-card>
    <el-table :data="rows1">
      <el-table-column prop="Date" width='120' label="Date">
      </el-table-column>
      <el-table-column prop="Mileage" label="Mileage"> </el-table-column>
      <el-table-column width='300' label="Source">
        <template slot-scope="scope">
          <div v-for="(item,index) in scope.row.source[0]? scope.row.source[0].source_lines : ''" :key="index">{{item}}</div>
          <div class="c-star" v-if="scope.row.source[1] && scope.row.source[1].rate.length != 0 ?  scope.row.source[1].rate.rate : false" style="display: flex;align-items:center;">
            <svg viewBox="0 0 18 18" fill="url(#star-gradient-details-1.0)" stroke="none">
              <defs>
              <linearGradient id="star-gradient-details-1.0">
              <stop offset="1.0" stop-color="#ffc400"></stop><stop offset="1.0" stop-color="#e0e0e0">
              </stop>
              </linearGradient>
              </defs>
              <g>
              <path d="M9, 12.95L13.64, 15.75L12.41, 10.48L16.5, 6.93L11.11, 6.47L9, 1.5L6.89, 6.47L1.5, 6.93L5.6, 10.48L4.37, 15.75z">
              </path>
              </g>
            </svg>
            <span style="margin-right:10px;">
               {{scope.row.source[1] && scope.row.source[1].rate.length != 0 ?  scope.row.source[1].rate.rate : 0}} / 5.0
            </span>
            <span style="text-decoration: underline;"> {{scope.row.source[1] && scope.row.source[1].rate.length != 0 ?  scope.row.source[1].rate.review : ''}} </span>
            <img style="margin-left:5px;" src="./assets/verifiedReview.svg" alt="">
          </div>
          <div style="display: flex;align-items:center;" v-if="scope.row.source[2] ? scope.row.source[2].favorite : ''">
            <svg t="1610376199713" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2554" width="24" height="24"><path d="M128 384c0-256 320-256 384-64 64-192 384-192 384 64 0 192-128 320-384 448-256-128-384-256-384-448z" fill="#F04D88" p-id="2555"></path></svg>
            {{scope.row.source[2] ? scope.row.source[2].favorite : ''}}
             Customer Favorites
          </div>
        </template>
      </el-table-column>
      <el-table-column label="">
        <template slot-scope="scope">
          <!-- <div>{{scope.row.icon}}</div> -->
          <img style="width:32px;height:32px;" v-if="scope.row.icon" src="./assets/ico_service.svg" alt="">
        </template>
      </el-table-column>
      <el-table-column width='320' label="Comments">
        <template slot-scope="scope">
          <div style="font-weight: bold;">{{scope.row.Comments.title}}</div>
          <div v-for="(item, index) in scope.row.Comments.comment_value" :key="index"> - {{item}}</div>
          <div v-if="scope.row.Comments.severity_scale" class="severity-scale" v-html="scope.row.Comments.severity_scale? scope.row.Comments.severity_scale : ''"></div>
          <div class="spacer-div"></div>
          <div v-if="scope.row.Comments.damage_location" class="poi-yellow-fill-container" v-html="scope.row.Comments.damage_location? scope.row.Comments.damage_location : ''"></div>
        </template>
      </el-table-column>
    </el-table>
    </div>
    <el-divider></el-divider>
    <div v-show="rows2.length != 0">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <i class="el-icon-user"></i>
        <span> {{owners2_desc.owner_purchased}}</span>
      </div>
      <div>{{owners2_desc.owner_type}}</div>
      <div>{{owners2_desc.drven_year}}</div>
    </el-card>
    
    <el-table :data="rows2" >
      <el-table-column prop="Date" width='120' label="Date">
      </el-table-column>
      <el-table-column prop="Mileage" label="Mileage"> </el-table-column>
      <el-table-column width='300' label="Source">
        <template slot-scope="scope">
          <!-- {{scope.row.source[1].rate.rate ? scope.row.source[1].rate.rate : false}} -->
          <div v-for="(item,index) in scope.row.source[0]? scope.row.source[0].source_lines : ''" :key="index">{{item}}</div>
          <div class="c-star" v-if="scope.row.source[1] && scope.row.source[1].rate.length != 0 ?  scope.row.source[1].rate.rate : false" style="display: flex;align-items:center;">
            <svg viewBox="0 0 18 18" fill="url(#star-gradient-details-1.0)" stroke="none">
              <defs>
              <linearGradient id="star-gradient-details-1.0">
              <stop offset="1.0" stop-color="#ffc400"></stop><stop offset="1.0" stop-color="#e0e0e0">
              </stop>
              </linearGradient>
              </defs>
              <g>
              <path d="M9, 12.95L13.64, 15.75L12.41, 10.48L16.5, 6.93L11.11, 6.47L9, 1.5L6.89, 6.47L1.5, 6.93L5.6, 10.48L4.37, 15.75z">
              </path>
              </g>
            </svg>
            <span style="margin-right:10px;">
              {{scope.row.source[1] && scope.row.source[1].rate.length != 0 ?  scope.row.source[1].rate.rate : 0}} / 5.0
            </span>
            <span style="text-decoration: underline;"> {{scope.row.source[1] && scope.row.source[1].rate.length != 0 ?  scope.row.source[1].rate.review : ''}} </span>
            <img style="margin-left:5px;" src="./assets/verifiedReview.svg" alt="">
          </div>
          <div style="display: flex;align-items:center;" v-if="scope.row.source[2] ? scope.row.source[2].favorite : ''">
            <svg t="1610376199713" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2554" width="24" height="24"><path d="M128 384c0-256 320-256 384-64 64-192 384-192 384 64 0 192-128 320-384 448-256-128-384-256-384-448z" fill="#F04D88" p-id="2555"></path></svg>
            {{scope.row.source[2] ? scope.row.source[2].favorite : ''}}
            Customer Favorites
          </div>
        </template>
      </el-table-column>
      <el-table-column label="">
        <template slot-scope="scope">
          <!-- <div>{{scope.row.icon}}</div> -->
          <img style="width:32px;height:32px;" v-if="scope.row.icon" src="./assets/ico_service.svg" alt="">
        </template>
      </el-table-column>
      <el-table-column width='320' label="Comments">
        <template slot-scope="scope">
          <div style="font-weight: bold;">{{scope.row.Comments.title}}</div>
          <div v-for="(item, index) in scope.row.Comments.comment_value" :key="index"> - {{item}}</div>
            <!-- <img :src="scope.row.Comments.damage_location? scope.row.Comments.damage_location : ''" alt=""> -->
          <div v-if="scope.row.Comments.severity_scale" class="severity-scale" v-html="scope.row.Comments.severity_scale? scope.row.Comments.severity_scale : ''"></div>
          <div class="spacer-div"></div>
          <div v-if="scope.row.Comments.damage_location" class="poi-yellow-fill-container" v-html="scope.row.Comments.damage_location? scope.row.Comments.damage_location : ''"></div>
            <!-- <img :src="scope.row.Comments.severity_scale? scope.row.Comments.severity_scale : ''" alt=""> -->
        </template>
      </el-table-column>
    </el-table>
    </div>
    <el-divider></el-divider>
    <div v-show="rows3.length != 0">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <i class="el-icon-user"></i>
        <span> {{owners3_desc.owner_purchased}}</span>
      </div>
      <div>{{owners3_desc.owner_type}}</div>
      <div>{{owners3_desc.drven_year}}</div>
    </el-card>
    
    <el-table :data="rows3">
      <el-table-column prop="Date" width='120' label="Date">
      </el-table-column>
      <el-table-column prop="Mileage" label="Mileage"> </el-table-column>
      <el-table-column width='300' label="Source">
        <template slot-scope="scope">
          <div v-for="(item,index) in scope.row.source[0]? scope.row.source[0].source_lines : ''" :key="index">{{item}}</div>
          
          <div class="c-star" v-if="scope.row.source[1] && scope.row.source[1].rate.length != 0 ?  scope.row.source[1].rate.rate : false" style="display: flex;align-items:center;">
            <svg viewBox="0 0 18 18" fill="url(#star-gradient-details-1.0)" stroke="none">
              <defs>
              <linearGradient id="star-gradient-details-1.0">
              <stop offset="1.0" stop-color="#ffc400"></stop><stop offset="1.0" stop-color="#e0e0e0">
              </stop>
              </linearGradient>
              </defs>
              <g>
              <path d="M9, 12.95L13.64, 15.75L12.41, 10.48L16.5, 6.93L11.11, 6.47L9, 1.5L6.89, 6.47L1.5, 6.93L5.6, 10.48L4.37, 15.75z">
              </path>
              </g>
            </svg>
            <span style="margin-right:10px;">
               {{scope.row.source[1] &&  scope.row.source[1].rate.length != 0 ?  scope.row.source[1].rate.rate : 0}} / 5.0
            </span>
            <span style="text-decoration: underline;"> {{scope.row.source[1] && scope.row.source[1].rate.length != 0 ?  scope.row.source[1].rate.review : ''}} </span>
            <img style="margin-left:5px;" src="./assets/verifiedReview.svg" alt="">
          </div>
          <div style="display: flex;align-items:center;" v-if="scope.row.source[2] ? scope.row.source[2].favorite : ''">
            <svg t="1610376199713" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2554" width="24" height="24"><path d="M128 384c0-256 320-256 384-64 64-192 384-192 384 64 0 192-128 320-384 448-256-128-384-256-384-448z" fill="#F04D88" p-id="2555"></path></svg>
            {{scope.row.source[2] ? scope.row.source[2].favorite : ''}}
             Customer Favorites
          </div>
        </template>
      </el-table-column>
      <el-table-column label="">
        <template slot-scope="scope">
          <!-- <div>{{scope.row.icon}}</div> -->
          <img style="width:32px;height:32px;" v-if="scope.row.icon" src="./assets/ico_service.svg" alt="">
        </template>
      </el-table-column>
      <el-table-column width='320' label="Comments">
        <template slot-scope="scope">
          <div style="font-weight: bold;">{{scope.row.Comments.title}}</div>
          <div v-for="(item, index) in scope.row.Comments.comment_value" :key="index"> - {{item}}</div>
          <div v-if="scope.row.Comments.severity_scale" class="severity-scale" v-html="scope.row.Comments.severity_scale? scope.row.Comments.severity_scale : ''"></div>
          <div class="spacer-div"></div>
          <div v-if="scope.row.Comments.damage_location" class="poi-yellow-fill-container" v-html="scope.row.Comments.damage_location? scope.row.Comments.damage_location : ''"></div>
        </template>
      </el-table-column>
    </el-table>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
  data() {
    return {
      titleTableData: [],
      sumOwnModuleData: [],
      vehicleDetails: {},
      owners1_desc: {},
      owners2_desc: {},
      owners3_desc: {},
      rows1: [],
      rows2: [],
      rows3: [],
      vinObj: {},
      sumOwnModuleHeader: [],
    };
  },
  methods: {
    setTitleTable(res) {
      let report_summery = res.report_summery;
      for (let key in report_summery) {
        let titleDataObj = {};
        titleDataObj.titleDesc = report_summery[key];
        this.titleTableData.push(titleDataObj);
      }
    },
    setSecondTable(res) {
      let sumOwnModule = res.sum_own_module.sum_own_module_rows.slice(1);
      let len = sumOwnModule.length;
      this.sumOwnModuleHeader = res.sum_own_module.sum_own_module_rows.slice(0,1)[0].slice(1);
      // console.log(this.sumOwnModuleHeader);
      
      for (let i = 0; i < len; i++) {
        let obj = {};
        obj.history = sumOwnModule[i][0];
        obj.owner1 = sumOwnModule[i][1];
        obj.owner2 = sumOwnModule[i][2];
        obj.owner3 = sumOwnModule[i][3];
        this.sumOwnModuleData.push(obj);
      }
    },
    getVehicleDetails (res) {
      this.vehicleDetails = res.vehicle_details
    },
    getOwnersDesc (res) {
        // console.log(res.details_module.owners.length>2)
      if (res.details_module.owners.length > 0) {
        this.owners1_desc = res.details_module.owners[0][0].owners_desc
      }
      if (res.details_module.owners.length > 1) {
        this.owners2_desc = res.details_module.owners[1][0].owners_desc
      }
      if (res.details_module.owners.length > 2) {
        // console.log(2)
        this.owners3_desc = res.details_module.owners[2][0].owners_desc
      }

    },
    getRows (res) {
      if (res.details_module.owners.length > 0) {
        let newData = res.details_module.owners[0][2].rows.slice(1);
        console.log(newData)
        for(let i = 0; i < newData.length; i++) {
          let obj = {};
          for(let j = 0; j < newData[i].length; j++) {
            obj.Date = newData[i][0].date;
            obj.Mileage = newData[i][1].mileage;
            obj.icon = newData[i][3].icon;
            obj.Comments = newData[i][4].comments;
            obj.source = newData[i][2].source;
          }
          this.rows1.push(obj);
        }
        // console.log(this.rows1)
      }
      if (res.details_module.owners.length > 1) {
        let newData2 = res.details_module.owners[1][2].rows.slice(1);
        console.log(newData2)
        for(let i = 0; i < newData2.length; i++) {
          let obj = {};
          for(let j = 0; j < newData2[i].length; j++) {
            obj.Date = newData2[i][0].date;
            obj.Mileage = newData2[i][1].mileage;
            obj.icon = newData2[i][3].icon;
            obj.Comments = newData2[i][4].comments;
            obj.source = newData2[i][2].source;
          }
          this.rows2.push(obj);
        }
      }
      if (res.details_module.owners.length > 2) {
        let newData3 = res.details_module.owners[2][2].rows.slice(1);
        console.log(newData3)
      // console.log(res.details_module.owners[0][2].rows.slice(1));
        for(let i = 0; i < newData3.length; i++) {
          let obj = {};
          for(let j = 0; j < newData3[i].length; j++) {
            obj.Date = newData3[i][0].date;
            obj.Mileage = newData3[i][1].mileage;
            obj.icon = newData3[i][3].icon;
            obj.Comments = newData3[i][4].comments;
            obj.source = newData3[i][2].source;
          }
          this.rows3.push(obj);
        }
      }
      
    }
  },
  created () {
    // console.log(window.location.href)
    var params = window.location.href.split("?")[1].split("&")

    params.map(v => this.vinObj[v.split("=")[0]] = v.split("=")[1])
    // console.log(this.vinObj)
  },
  mounted() {
    let self = this;
    this.$axios
      // .get("https://tapsorder.com/api/carfox/report/" + this.vinObj.vin)
      .get('https://bigthumb.thecrafttime.com/api/carfox/report/'+ this.vinObj.vin)
      .then((res) => {
        console.log(res);
        self.setTitleTable(res);
        self.setSecondTable(res);
        self.getVehicleDetails(res);
        self.getOwnersDesc(res);
        self.getRows(res);
        // this.titleTableData = res.report_summery
      });
  },
};
</script>

<style lang="scss">
.el-card__header {
  background-color: #409eff;
  color: #fff;
}
.non-header .el-table__header-wrapper {
  display: none;
}
.c-star svg {
    height: 24px;
    width: 24px;
    flex: none;
}
.severity-scale {
    width: 278px;
    height: 99px;
    position: relative;
    color: #424242;
    border: 1px solid #e0e0e0;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 5px;
    margin-left: -5px;
}
.severity-scale-fill {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}
.severity-scale-label {
    position: relative;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: -.24px;
    margin-bottom: 15px;
    color: #212121;
    text-align: center;
}
.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
}
.severity-scale-info-icon {
    color: #3777bc;
    cursor: pointer;
    font-size: 16px;
    position: relative;
    top: 4px;
    left: 2px;
}
.severity-scale-slider {
    width: 212px;
    height: 12px;
    border: 1px solid #212121;
    position: relative;
    text-align: left;
}
.severity-scale svg {
    position: relative;
    border: 1px solid #212121;
    margin-top: -4px;
    height: 19px;
    margin-left: 35px;
    width: 50px;
    overflow: hidden;
}
.severity-scale-ruler {
    position: relative;
    display: flex;
    justify-content: space-around;
    font-size: 10px;
    width: 100%;
    margin-top: 10px;
    color: #212121;
    text-align: center;
}
.poi-yellow-fill-container {
    position: relative;
    z-index: 0;
    bottom: 0;
    width: 278px;
    border: 1px solid #E0E0E0;
    border-radius: 3px;
    margin-left: -5px;
    color: #212121;
    height: 275px;
    text-align: center;
}
.yellow-fill {
    width: 100%;
    height: 100%;
    position: relative;
    top: 0;
    border-radius: 3px;
    width: 100%;
    height: 100%;
    position: relative;
    top: 0;
}
.poi-container {
    position: absolute;
    z-index: 1;
    top: 0;
    width: 278px;
    display: block;
    background-color: unset;
    border: none;
    max-height: none;
    height: 275px;
    color: #212121;
    text-align: center;
}
.scale-text {
    color: #424242;
    letter-spacing: -0.24px;
    display: flex;
    margin-top: 15px;
    margin-bottom: 10px;
    align-items: center;
    max-height: 20px;
    font-size: 12px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #212121;
    text-align: center;
}
.poi div {
    color: #212121;
    text-align: center;
    font-size: 10px;
}
.poi-image {
    display: inline-block;
    vertical-align: middle;
    margin: 3px;
    width: 130px;
    height: 182px;
}
.poi-image .ie-wrap {
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    position: relative;
}
.poi-image .ie-wrap> svg {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    height: calc(182 / 130 * 100%);
}
.spacer-div {
    min-height: 10px;
}
.poi-image.front .front, .poi-image.left-side .left-side, .poi-image.right-side .right-side, .poi-image.rear .rear, .poi-image .roof, .poi-image.left-front .left-front, .poi-image.right-front .right-front, .poi-image.left-rear .left-rear, .poi-image.right-rear .right-rear {
  display: none;
}
</style>
