import Vue from 'vue'
import App from './App.vue'
import axios from './http/http'
import { Table, TableColumn, Divider, Card, Button} from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.prototype.$axios = axios

Vue.config.productionTip = false

Vue.use(Table).use(TableColumn).use(Divider).use(Card).use(Button)

new Vue({
  render: h => h(App),
}).$mount('#app')
